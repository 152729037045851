.card {
    --border-radius: var(--space-8);

    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--color-background-variant);
    cursor: pointer;
}

.card::after {
    display: none;
    position: absolute;
    inset: 0;
    border-radius: var(--border-radius);
    background-color: var(--color-neutral-900-30);
    content: '';
}

.card:hover::after {
    display: block;
}

.selectorCardContent {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: var(--space-16);
}

.image {
    border-radius: var(--border-radius);
}

.selectBadge {
    display: flex;
    position: absolute;
    top: var(--space-12);
    right: var(--space-12);
    align-items: center;
    justify-content: center;
    width: var(--space-32);
    height: var(--space-32);
    border-radius: 50%;
    background: var(--color-primary-contrast-neutral);
}

.icon {
    width: var(--space-24);
    height: var(--space-24);
    color: var(--color-primary-main);
}

.backgroundSoftGreen {
    background-color: var(--color-success-main);
    color: var(--color-primary-contrast-neutral);
}

.backgroundBeige {
    background-color: var(--color-background-variant);
    color: var(--color-text-main);
}

.backgroundGreen {
    background-color: var(--color-primary-main);
    color: var(--color-primary-contrast-colored);
}

.backgroundRed {
    background-color: var(--color-accent-main);
    color: var(--color-accent-contrast-neutral);
}

.backgroundImage {
    padding: 0;
    color: var(--color-text-overlay);
}
