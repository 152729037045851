.wrapper {
    display: flex;
    flex-wrap: wrap;
    place-content: flex-start space-between;
    align-items: center;
    margin: calc(-0.5 * var(--space-56)) 0;
}

.wrapper .section {
    display: flex;
    flex: 1 0 0;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    margin: calc(0.5 * var(--space-56)) 0;
}

.imageWrapper + .imageWrapper {
    margin-left: var(--space-24);
}

.sustainability .imageWrapper + .imageWrapper {
    margin-left: var(--space-4);
}
