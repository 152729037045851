.footerSocials {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 50px;
    gap: var(--space-16);
}

.icon {
    width: var(--space-32);
    height: var(--space-32);
}

.link {
    display: flex;
    align-items: center;
    justify-content: center;
}
