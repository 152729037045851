.primaryNav {
    --primary-nav-height: var(--space-56);

    z-index: var(--z-index-appbar);
    position: relative;
    width: 100%;
    background-color: var(--color-green-800);
}

.primaryNav.previewMode {
    background-color: var(--color-blue-800);
}

@media (max-width: 1199.98px) {
    .isOpen {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        overflow-y: auto;
    }
}
