.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: var(--space-16);
}

@media (min-width: 768px) {
    .container {
        width: 391px;
    }
}
