.container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: var(--space-16) var(--space-24);
    gap: var(--space-8);
    border-radius: var(--space-8);
    background-color: var(--color-neutral-100);
}

.buttonsWrapper {
    display: flex;
    gap: var(--space-24);
}

.button {
    text-decoration: none;
}
