.wrapper {
    display: flex;
    flex-direction: column;

    --icon-width: var(--space-24);
    --padding-input-x: var(--space-16);
    --margin-arrow: var(--space-16);
}

.selectWrapper {
    display: flex;
    position: relative;
    align-items: center;
    border-radius: var(--space-8);
    background-color: var(--color-deprecated-white);
    box-shadow: 0 0 0 1px var(--color-deprecated-ink-lighter) inset;
}

.select {
    appearance: none;
    width: 100%;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    padding: 1.1rem var(--padding-input-x);
    padding-right: calc(var(--margin-arrow) + var(--icon-width) + var(--padding-input-x));
    border: none;
    outline: 0;
    background: none;
}

.selectWrapper,
.select {
    color: var(--color-deprecated-ink);
}

.selectArrow {
    position: absolute;
    right: var(--margin-arrow);
    flex-shrink: 0;
    width: var(--space-24);
    height: var(--space-24);
    pointer-events: none;
}

.head {
    margin-bottom: var(--space-8);
}

.textError {
    margin-top: var(--space-8);
    color: var(--color-deprecated-red);
}

.selectWrapper:hover {
    box-shadow: 0 0 0 2px var(--color-deprecated-ink-lighter) inset;
}

.selectWrapper:focus {
    box-shadow: 0 0 0 2px var(--color-deprecated-ink-light) inset;
}

.selectError {
    box-shadow: 0 0 0 1px var(--color-deprecated-red) inset;
}

.selectError:hover,
.selectError:focus-within {
    box-shadow: 0 0 0 2px var(--color-deprecated-red) inset;
}

.selectDisabled,
.selectDisabled:hover,
.selectDisabled:focus-within {
    background-color: var(--color-deprecated-ink-extra-light);
    box-shadow: 0 0 0 1px var(--color-deprecated-ink-lighter) inset;
    color: var(--color-deprecated-ink-lighter);
}
