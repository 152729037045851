.container {
    --gap: var(--space-16);

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;
    gap: var(--gap);
}

.container > * {
    width: calc(50% - var(--gap));
}

@media (min-width: 768px) {
    .container {
        flex-wrap: nowrap;
        justify-content: center;
    }

    .container > * {
        width: 100%;
    }
}
